/* @flow */

import { Button, EmailInput, TriviLink } from 'components'
import type { Filter as GridFilter, OrganizationMember, Task, TasksSearchResult, UserProfile } from 'types'
import React, { Component } from 'react'
import { Tab, Tabs } from 'components/Tabs'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { billingRoute, internalUsersRoute } from '../routing/routes'

import Helmet from 'react-helmet'
import MyTasks from '../components/my-tasks'
import OrganizationOverview from '../components/organization-overview'
import OrganizationStatisticsGrid from '../containers/organization-statistics-grid'
import StickyNotes from '../containers/sticky-notes'
import { isEnabled } from 'utils/features'
import styles from './internal-dashboard.css'

const TRANSLATION_PREFIX = 'internal.widget.organizationOverview.'

const TRANSLATIONS = {
	myOrganizations: TRANSLATION_PREFIX + 'myOrganizations',
	representedOrganizations: TRANSLATION_PREFIX + 'representedOrganizations',
	all: TRANSLATION_PREFIX + 'all',
}

const ALL_FILTER = 0
const MY_ORGANIZATION_FITLER = 1
const REPRESENTED_ORGANIZATION_FITLER = 2

type Filter = 0 | 1 | 2

type Props = {|
	...WithTranslateProps,
	me: ?UserProfile,
	myTasks: ?TasksSearchResult,
	organizations: ?Array<OrganizationMember>,
	showExtract: boolean,
	showEditInternalUsers: boolean,
	canLogInAsUser: boolean,
	onTaskClick: (task: Task) => void,
	logAsUser: (login: string) => Promise<void>,
	changeCurrentOrganization: (organizationId: string) => Promise<void>,
	extractNextAccountingDocument: () => void,
	getAccountingDocumentsToExtractCount: () => Promise<number>,
|}

type State = {|
	accountingDocumentsCountToExtract: number,
	activeFilter: number,
	login: string,
|}

class InternalDashboard extends Component<Props, State> {
	state: State = {
		accountingDocumentsCountToExtract: 0,
		activeFilter: ALL_FILTER,
		login: '',
	}

	getCurrentFilter(): GridFilter {
		switch (this.state.activeFilter) {
			case MY_ORGANIZATION_FITLER:
				return [
					{
						field: 'internalUserMembershipType',
						value: 2,
					},
				]
			case REPRESENTED_ORGANIZATION_FITLER:
				return [
					{
						field: 'internalUserMembershipType',
						value: 3,
					},
				]
			default:
				return []
		}
	}

	UNSAFE_componentWillMount() {
		if (this.props.showExtract) {
			this.props.getAccountingDocumentsToExtractCount().then((accountingDocumentsCountToExtract: number) => {
				this.setState({ accountingDocumentsCountToExtract })
			})
		}
	}

	onLoginChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({ login: value })
		}
	}

	onLoginAsUser = () => {
		this.state.login && this.props.logAsUser(this.state.login)
	}

	handleTabChange = (activeFilter: Filter) => {
		this.setState({ activeFilter })
	}

	render() {
		const { t, me, changeCurrentOrganization, myTasks, organizations } = this.props

		if (!organizations || !me) {
			return null
		}

		return (
			<div className={styles.root}>
				<Helmet>
					<title>{t('internal.header')}</title>
				</Helmet>
				<h1>{t('internal.header')}</h1>
				{this.props.canLogInAsUser && (
					<div className={styles.loginAs}>
						<div className={styles.MultiEmailInput}>
							<EmailInput
								labelText={t('internal.logInAsUser')}
								compact
								value={this.state.login}
								onChange={this.onLoginChange}
								autoTestId="internal-login-as-user"
							/>
						</div>
						<Button tertiary labelText={t('user.login.submitLabel')} onClick={this.onLoginAsUser} />

						<div className={styles.tabs}>
							<Tabs
								large
								tall
								shrink
								tabItemContainerStyle={{ borderBottom: 'none' }}
								value={this.state.activeFilter}
								onChange={this.handleTabChange}
							>
								<Tab label={t(TRANSLATIONS.all)} value={ALL_FILTER} />
								<Tab label={t(TRANSLATIONS.myOrganizations)} value={MY_ORGANIZATION_FITLER} />
								<Tab label={t(TRANSLATIONS.representedOrganizations)} value={REPRESENTED_ORGANIZATION_FITLER} />
							</Tabs>
						</div>
					</div>
				)}
				<div className={styles.container}>
					<div className={styles.row}>
						<OrganizationStatisticsGrid
							filter={this.getCurrentFilter()}
							changeCurrentOrganization={changeCurrentOrganization}
							extractNextAccountingDocument={this.props.extractNextAccountingDocument}
							showExtract={this.props.showExtract}
							accountingDocumentsCountToExtract={this.state.accountingDocumentsCountToExtract}
							organizationsIds={
								this.props.organizations &&
								this.props.organizations.map((o: OrganizationMember) => o.organizationId || '')
							}
						/>
					</div>
					<div className={styles.row}>
						<div className={styles.left}>
							<StickyNotes />
						</div>
						<div className={styles.right}>
							<MyTasks myTasks={myTasks} organizations={organizations} onTaskClick={this.props.onTaskClick} />
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.full}>
							<OrganizationOverview changeCurrentOrganization={changeCurrentOrganization} currentUser={me} />
						</div>
					</div>
					<div className={styles.row}>
						{this.props.showEditInternalUsers && (
							<div className={styles.full}>
								<TriviLink routeFn={internalUsersRoute}>
									<Button tall wide labelText={t('internalUsers.header')} />
								</TriviLink>
							</div>
						)}
						{isEnabled('billings') && (
							<div className={styles.full}>
								<TriviLink routeFn={billingRoute}>
									<Button tall wide labelText={t('internal.billing')} />
								</TriviLink>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(InternalDashboard)
