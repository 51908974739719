// @flow
import type { Filter, SearchFilter } from 'types'

import { getFilter } from 'modules/data-grid-next/domain/filter'

export function processingStateFilterTransform(item: SearchFilter): Filter {
	let filter: Filter = []
	switch (item.value) {
		case undefined:
			filter.push(
				{
					field: 'isProcessedWithOK',
					value: undefined,
				},
				{
					field: 'processingState',
					value: undefined,
				},
			)
			break
		case 'processedWithOk':
			filter.push(
				{
					field: 'isProcessedWithOK',
					value: true,
				},
				{
					field: 'processingState',
					value: undefined,
				},
			)
			break
		case 'processedWithoutOk':
			filter.push(
				{
					field: 'isProcessedWithOK',
					value: false,
				},
				{
					field: 'processingState',
					value: undefined,
				},
			)
			break
		case 'waitingForProcessing':
			filter.push(
				{
					field: 'processingState',
					value: 2,
				},
				{
					field: 'isProcessedWithOK',
					value: undefined,
				},
			)
			break
		case 'processedWithError':
			filter.push(
				{
					field: 'processingState',
					value: 3,
				},
				{
					field: 'isProcessedWithOK',
					value: undefined,
				},
			)
			break
	}
	return filter
}

export function paymentFilterTransform(item: SearchFilter): Filter {
	let filter = []
	switch (item.value) {
		case 'paid':
			filter.push(
				{
					field: 'payments.paidConfirmed',
					value: true,
				},
				{
					field: 'payments.paid',
					value: false,
				},
				{
					field: 'payments.payments.any()',
				},
			)
			break
		case 'unpaid':
			filter.push(
				{
					field: 'payments.paidConfirmed',
					value: false,
				},
				{
					field: 'payments.paid',
					value: false,
				},
				{
					field: 'payments.payments.any()',
				},
			)
			break
		case 'paidPartially':
			filter.push(
				{
					field: 'payments.paidConfirmed',
					value: false,
				},
				{
					field: 'payments.paid',
					value: false,
				},
				{
					field: 'payments.payments.any()',
					value: true,
				},
			)
			break
		case 'paidManually':
			filter.push(
				{
					field: 'payments.paidConfirmed',
					value: false,
				},
				{
					field: 'payments.paid',
					value: true,
				},
				{
					field: 'payments.payments.any()',
				},
			)
			break
		default:
			filter.push(
				{
					field: 'payments.paidConfirmed',
				},
				{
					field: 'payments.paid',
				},
				{
					field: 'payments.payments.any()',
				},
			)
			break
	}
	return filter
}

export function paymentToFilterItemTransform(filter: Filter, field: string): SearchFilter {
	const item: SearchFilter = {
		field: field,
	}
	const paidConfirmed = getFilter(filter, 'payments.paidConfirmed')
	const paidAny = getFilter(filter, 'payments.payments.any()')
	const paid = getFilter(filter, 'payments.paid')

	if (paidConfirmed && paidConfirmed.value) {
		item.value = 'paid'
	} else if (paidAny && paidAny.value) {
		item.value = 'paidPartially'
	} else if (paid && paid.value) {
		item.value = 'paidManually'
	} else if (paid && !paid.value && paidConfirmed && !paidConfirmed.value) {
		item.value = 'unpaid'
	}

	return item
}

export function processingStateToFilterItemTransform(filter: Filter, field: string): SearchFilter {
	const item: SearchFilter = {
		field: field,
	}
	const isProcessedWithOK = getFilter(filter, 'isProcessedWithOK')
	const processingState = getFilter(filter, 'processingState')
	if (isProcessedWithOK && isProcessedWithOK.value) {
		item.value = 'processedWithOk'
	} else if (isProcessedWithOK && !isProcessedWithOK.value) {
		item.value = 'processedWithoutOk'
	} else if (processingState && processingState.value === 2) {
		item.value = 'waitingForProcessing'
	} else if (processingState && processingState.value === 3) {
		item.value = 'processedWithError'
	}
	return item
}
