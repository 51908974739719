/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentAction,
	AccountingDocumentActions,
	AccountingDocumentDirection,
	AccountingDocumentScan,
	AccountingDocumentType,
	AccountingDocumentView,
	CashRegister,
	DataGridId,
	FileDescription,
	Filter,
	Task,
	WithRouterProps,
} from 'types'
import {
	AccountingDocumentType_Number,
	Number_AccountingDocumentAssignedDirection,
	Number_AccountingDocumentAssignedType,
	Number_AccountingDocumentDirection,
	Number_AccountingDocumentType,
} from 'types/convertor'
import { FileIcon, PaymentDialog, TriviLink } from 'components'
import React, { PureComponent } from 'react'
import {
	default as SidebarDataGrid,
	type Props as SidebarDataGridProps,
} from 'modules/data-grid-next/components/sidebar-data-grid'
import {
	type WithAccountingDocumentActionsProps,
	type WithNotifyProps,
	type WithOrganizationSettingsProps,
	type WithTranslateProps,
	withAccountingDocumentActions,
	withNotify,
	withOrganizationSettings,
	withTranslate,
} from 'wrappers'
import { canBeAccDocCopied, getAccountingDocumentActions, isGridActionAllowed } from 'helpers'

import { ACCOUNTING_DOCUMENT_TITLES } from '../../constants'
import AccountingDocumentAdvancedFilter from 'modules/accounting-document/components/accounting-document-list/advanced-filter'
import { AccountingDocumentTypeNumber_isCashRegister } from '../../../../types/operations'
import { AccountingDocument_isNonCashRegister } from 'types/operations'
import ArrowLeft from 'components/svg-icons/hardware/keyboard-arrow-left'
import CashRegisterDialog from 'modules/common/containers/cash-register-dialog'
import CashRegisterDirectionViewSwitcher from 'modules/cash-register/containers/cash-register-direction-view-switcher'
import CashRegisterPaymentsAdvancedFilter from 'modules/cash-register/components/cash-register-payments-advanced-filter'
import ConfirmDialog from 'components/confirm-dialog'
import CreditNoteDialog from '../../containers/invoice-elements/credit-note-dialog'
import DeleteDialog from 'components/delete-dialog'
import DetailDirectionViewSwitcher from 'modules/accounting-document/containers/accounting-document-list/detail-direction-view-switcher'
import DocumentPreviewDialog from '../../components/accounting-document-list/document-preview-dialog'
import { EMPTY_ARRAY } from 'trivi-constants'
import EditInvoiceTypeDocument from '../../containers/invoices/edit-invoice-type-document'
import EditScannedInvoice from '../../containers/invoices/edit-scanned-invoice'
import EditSimpleDocument from '../../containers/invoices/edit-simple-document'
import EditSimplifiedInvoice from '../../containers/invoices/edit-simplified-invoice'
import MoreActions from '../../containers/invoice-elements/more-actions'
import SendEmailDialog from 'modules/common/containers/send-email-dialog'
import SendReminderEmailDialog from 'modules/common/containers/send-reminder-email-dialog'
import TaskEdit from 'modules/task/containers/task-edit'
import Tracking from 'utils/tracking'
// import { isEetSent } from 'modules/accounting-document/domain/eet'
import { accountingDocumentPublicRoute } from '../../routing/routes'
import { accountingDocumentsRoute } from 'modules/accounting-document/routing/routes'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'
import { colors } from 'variables'
import { getAccDocFilterName } from '../../domain/accounting-document'
import { getCashRegisterFilterName } from '../../../cash-register/domain/cash-register'
import { getCompactInfoColumn } from '../../components/data-grid-columns'
import { getIsExtractionQueue } from 'modules/accounting-document/helpers'
import { internalDashboardRoute } from 'modules/internal/routing/routes'
import { isAccDocReadOnly } from 'modules/accounting-document/domain/accounting-document'
import memoize from 'memoize-one'
import { openFile } from '../../../file/domain'
import { showCashbotPanelOnAccDoc } from 'modules/cashbot/domain'
import styles from './edit-accounting-document.css'
import { withRouter } from 'react-router'

const DATA_TYPE = 'accountingDocuments'
const DataGrid: React$ComponentType<SidebarDataGridProps<typeof DATA_TYPE>> = SidebarDataGrid

const getDataGridId = (props: ComponentProps): ?DataGridId => {
	const cashRegisterId: ?string = props.accountingDocument && props.accountingDocument.cashRegisterId
	let gridStateKey = ''

	if (cashRegisterId) {
		gridStateKey = 'cashRegisterPayments' + cashRegisterId
	} else {
		gridStateKey = 'accountingDocuments'
	}

	if (props.direction === 'unknown' && !cashRegisterId) {
		gridStateKey = 'accountingDocuments'
	}

	// $FlowFixMe variabilní DataGridId ?
	return gridStateKey
}

type CommonProps = {|
	accountingDocumentId: string,
	internal: boolean,
	onEditClick: () => void,
	scansCount: number,
	view: 'default' | 'scans',
	readonly: boolean,
	onViewChange: (view: 'default' | 'scans') => void,
	storing: boolean,
	MoreActions: any,
	accountingDocumentState: string,
	showEditButton: boolean,
	showStateChanger: boolean,
	showSendEmailButton: boolean,
	showSaveAndStopExtraction: boolean,
	showCashbotPanel: boolean,
	isCashRegister: boolean,
|}

type Props = {|
	accountingDocumentId: string,
	readonly: boolean,
|}

type ComponentState = {|
	view: 'default' | 'scans',
	accountingDocumentId: string,
	highlightedRows: Array<string>,
	availableActions: ?AccountingDocumentActions,
	availableActionsLoading: boolean,
	creditNoteOpen: boolean,
	dialogRelatedDocument: ?string,
	accountingDocuments: ?Array<AccountingDocument>,
	paymentOpen: boolean,
	sendEmailDialogDocument: ?AccountingDocument,
	sendReminderEmailDialogDocument: ?AccountingDocument,
	// newTaskDialogOpened: boolean,
	cashRegisterSelectionDialogOpened: boolean,
	isConfirmDialogOpen: boolean,
|}

type ComponentProps = {|
	...Props,
	...WithTranslateProps,
	...WithNotifyProps,
	...WithRouterProps,
	...WithAccountingDocumentActionsProps,
	...WithOrganizationSettingsProps,
	accountingDocument: ?AccountingDocument,
	lastCreatedDocument: ?AccountingDocument,
	possibleStates: ?Array<string>,
	scans: ?Array<AccountingDocumentScan>,
	storing: ?boolean,
	internal: ?boolean,
	forceGoOnEditRoute: boolean,
	preProcessingAccountingDocumentPreview: boolean,
	direction: ?AccountingDocumentDirection,
	view: AccountingDocumentView,
	defaultFilter: ?Filter,
	loadAccountingDocumentDefaults: (
		accountingDocumentType: ?AccountingDocumentType,
		accountingDocumentDirection: ?AccountingDocumentDirection,
	) => void,
	validateAccountingDocument: (accountingDocumentId: string, state: string) => Promise<boolean>,
	// changeEET: (operation: EETOperation) => void,
|}

class EditAccountingDocument extends PureComponent<ComponentProps, ComponentState> {
	state: ComponentState = {
		view: 'default',
		accountingDocumentId: this.props.accountingDocumentId,
		highlightedRows: [this.props.accountingDocumentId],
		availableActions: null,
		creditNoteOpen: false,
		dialogRelatedDocument: null,
		accountingDocuments: null,
		paymentOpen: false,
		sendEmailDialogDocument: null,
		sendReminderEmailDialogDocument: null,
		availableActionsLoading: false,
		// newTaskDialogOpened: false,
		cashRegisterSelectionDialogOpened: false,
		isConfirmDialogOpen: false,
	}

	filter: Filter
	gridStateKey: ?DataGridId
	gridRefresh: Function = () => {}

	constructor(props: ComponentProps) {
		super(props)
		this.updateLayout(this.isReadOnly())
	}

	UNSAFE_componentWillMount() {
		this.setViewByAccountingDocument(this.props)
	}

	componentWillUnmount() {
		this.updateLayout(false)
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		const nextReadOnly: boolean = this.isReadOnly(nextProps)
		const currentReadOnly: boolean = this.isReadOnly(this.props)
		if (nextReadOnly !== currentReadOnly) {
			this.updateLayout(nextReadOnly)
			nextReadOnly && this.setState({ view: 'default' })
		}

		// NEW DOCUMENT
		if (
			(nextProps.accountingDocument && nextProps.accountingDocument.id) !==
			(this.props.accountingDocument && this.props.accountingDocument.id)
		) {
			this.setViewByAccountingDocument(nextProps)
		}

		if (nextProps.accountingDocumentId !== this.props.accountingDocumentId) {
			this.props.loadAccountingDocument(nextProps.accountingDocumentId)
		}
	}

	componentDidMount() {
		const { state } = this.props.location

		if (!(state && (state.isNew || state.fromRedirect)) || !this.props.accountingDocument) {
			this.props.loadAccountingDocument(this.state.accountingDocumentId)
		}

		if (!this.props.organizationSettingsOpen) {
			this.props.loadOrganizationSettingsOpen()
		}

		this.updateLayout(this.isReadOnly())
	}

	componentDidUpdate(prevProps: ComponentProps) {
		if (prevProps.accountingDocumentId !== this.props.accountingDocumentId) {
			if (!this.props.accountingDocument) {
				this.props.loadAccountingDocument(this.props.accountingDocumentId)
			}
			if (!this.props.organizationSettings) {
				this.props.loadOrganizationSettings()
			}

			this.updateLayout(this.isReadOnly())
		}

		if (
			this.props.accountingDocument &&
			(this.props.accountingDocument.type !== prevProps.accountingDocument?.type ||
				this.props.accountingDocument.direction !== prevProps.accountingDocument?.direction)
		) {
			this.props.loadAccountingDocumentDefaults(
				Number_AccountingDocumentType(this.props.accountingDocument.type),
				Number_AccountingDocumentDirection(this.props.accountingDocument.direction),
			)
		}
	}

	isReadOnly(props: ComponentProps = this.props): boolean {
		const {
			readonly,
			internal,
			accountingDocument,
			canEditIssuedAccountingDocuments,
			canEditReceivedAccountingDocuments,
		} = props

		return (
			readonly ||
			isAccDocReadOnly(
				accountingDocument,
				internal,
				canEditIssuedAccountingDocuments,
				canEditReceivedAccountingDocuments,
			)
		)
	}

	isShowSendEmailButtonVisible = (accountingDocument: ?AccountingDocument): boolean => {
		if (!accountingDocument) return false
		const direction = Number_AccountingDocumentAssignedDirection(accountingDocument.direction)

		return !!(this.isReadOnly() && this.props.readonly && 'issued' === direction)
	}

	setViewByAccountingDocument(props: ComponentProps) {
		const documentState: ?string = (props.accountingDocument && props.accountingDocument.state) || null
		let view = 'default'

		if (!this.isReadOnly(props) && props.internal && ['Draft', 'Extracted', 'Extracting'].indexOf(documentState) > -1) {
			view = 'scans'
		}

		this.setState({ view })
	}

	onViewChange = (view: 'default' | 'scans') => {
		this.setState({ view })
	}

	onClose = () => {
		if (this.props.lastCreatedDocument === this.props.accountingDocument) {
			this.props.removeAccountingDocument(this.props.accountingDocumentId)
			Tracking.trackAccDocDeleted(this.props.accountingDocumentId, 'concept')
		}
		getIsExtractionQueue(this.props) && this.trackAccDocExtractingCanceled()
	}

	onEditClick = () => {
		this.props.goOnEditAccountingDocumentRoute(this.props.accountingDocumentId)
	}

	onBackButtonClick = (event?: ?Event, cashRegisterId?: ?string, direction?: number) => {
		const { accountingDocument, history } = this.props

		if (history.location.state && history.location.state.goBack) return history.goBack()

		cashRegisterId = cashRegisterId || (accountingDocument && accountingDocument.cashRegisterId)
		if (cashRegisterId) {
			history.push(cashRegisterRoute(cashRegisterId))
			return
		}
		history.push(
			accountingDocumentsRoute(
				Number_AccountingDocumentDirection(direction) ||
					this.props.direction ||
					Number_AccountingDocumentDirection(accountingDocument && accountingDocument.direction),
			),
		)
	}

	changeDocument = (accountingDocument: AccountingDocument, isCashRegister?: boolean) => {
		const accountingDocumentId = accountingDocument.id || ''
		this.setState({
			accountingDocumentId,
			highlightedRows: [accountingDocumentId],
		})
		this.props.loadAccountingDocument(accountingDocumentId)
		this.props.forceGoOnEditRoute
			? this.props.goOnEditAccountingDocumentRoute(accountingDocumentId)
			: isCashRegister
			? this.props.goOnShowSameDirectionAccountingDocumentRoute(accountingDocumentId)
			: this.props.goOnShowAccountingDocumentRoute(accountingDocumentId)
	}

	closeEmailSendDialog = () => {
		this.setState({ sendEmailDialogDocument: null })
	}

	closeReminderEmailSendDialog = () => {
		this.setState({ sendReminderEmailDialogDocument: null })
	}

	onSendDocument = (emails: Array<string>, templateId: number) => {
		const { sendEmailDialogDocument } = this.state
		this.props.sendAccountingDocument((sendEmailDialogDocument && sendEmailDialogDocument.id) || '', templateId, emails)
		this.closeEmailSendDialog()
	}

	onSendReminder = (emails: Array<string>, templateId: number) => {
		const { sendReminderEmailDialogDocument } = this.state
		this.props.sendReminderAccountingDocument(
			(sendReminderEmailDialogDocument && sendReminderEmailDialogDocument.id) || '',
			templateId,
			emails,
		)
		this.closeReminderEmailSendDialog()
	}

	hideCreditNote = () => {
		this.setState({ creditNoteOpen: false })
	}

	saveCreditNote = (value: { date: string, files: Array<FileDescription> }) => {
		const { dialogRelatedDocument } = this.state
		this.setState({ creditNoteOpen: false })
		this.props.confirmCreditNote(dialogRelatedDocument, value)
	}

	generateNewTask = (): Task => {
		const { accountingDocument } = this.props
		return {
			attachedAccountingDocumentIds: [
				{
					accountingDocumentId: accountingDocument ? accountingDocument.id : '',
					accountingDocumentNo: accountingDocument ? accountingDocument.accountingDocumentNo : '',
				},
			],
		}
	}

	onAction = (action: string, document: AccountingDocument) => {
		const { t } = this.props
		const direction = document.direction
		const deleteDocument = () => {
			const cashRegisterId = document && document.cashRegisterId
			document.id &&
				this.props.removeAccountingDocument(document.id).then(() => {
					document.id && Tracking.trackAccDocDeleted(document.id, 'manual')
					this.onBackButtonClick(null, cashRegisterId, direction)
				})
		}

		const createTemplate = async () => {
			if (document.id) {
				const resp: any = await this.props.createTemplate(document.id)
				resp && resp.success && this.props.notify(t('templates.templateCreated'), 'success')
			}
		}

		switch (action) {
			case 'accdoc_set_processing_state':
				ConfirmDialog(t('dialogs.changeStateToProcessed'), {
					okLabel: t('dialogs.yesOption'),
					waitForConfirm: true,
				}).then(() => {
					this.props.defaultOnAction(action, document, this.gridRefresh)
				})
				break
			case 'accdoc_remove':
				DeleteDialog({
					title: t('dialogs.deleteQuestion'),
				}).then(() => {
					const nextStep = () => {
						if (document.processingState == 0) return deleteDocument()
						ConfirmDialog(t('dialogs.deleteFromESO'), {
							okLabel: t('dialogs.yesOption'),
							cancelLabel: t('dialogs.noOption'),
							waitForConfirm: true,
						}).then(deleteDocument)
					}
					// if (isEetSent(document.countryVariantSpecific)) {
					// 	ConfirmDialog(t('dialogs.documentStateChange'), {
					// 		okLabel: this.props.t('dialogs.documentStateChangeYes'),
					// 		cancelLabel: this.props.t('dialogs.documentStateChangeNo'),
					// 	}).then(
					// 		() => {
					// 			this.props.changeEET('cancel_eet')
					// 			nextStep()
					// 		},
					// 		() => nextStep(),
					// 	)
					// } else {
					nextStep()
					// }
				})
				break
			case 'accdoc_send_email': {
				this.setState({ sendEmailDialogDocument: document })
				break
			}
			case 'accdoc_send_due_date': {
				this.setState({ sendReminderEmailDialogDocument: document })
				break
			}
			case 'accdoc_get_permalink': {
				document.permalinkToken && this.props.history.push(accountingDocumentPublicRoute(document.permalinkToken))
				break
			}
			case 'accdoc_create_template': {
				createTemplate()
				break
			}
			case 'accdoc_pay_transfer':
				if (document.id != null) {
					this.setState({
						accountingDocuments: [document],
						paymentOpen: true,
					})
				}
				break
			case 'accdoc_confirm_creditnote': {
				if (document.id) {
					this.setState({ dialogRelatedDocument: document.id, creditNoteOpen: true })
				}
				break
			}
			case 'accdoc_copy':
				ConfirmDialog(t('dialogs.copyDocumentQuestion'), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
				}).then(() => {
					this.props.copyAccountingDocument(document).then(this.gridRefresh)
				})
				break
			case 'accdoc_reextract':
				ConfirmDialog(t('dialogs.reextractQuestion'), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
				}).then(() => {
					this.props.reextractAccountingDocument(document, true).then(this.gridRefresh)
				})
				break
			// case 'accdoc_task_create':
			// 	this.setState({ newTaskDialogOpened: true })
			// 	break
			// case 'accdoc_send_eet':
			// 	ConfirmDialog(t('dialogs.sendEet'), {
			// 		okLabel: t('dialogs.yesOption'),
			// 		cancelLabel: t('dialogs.noOption'),
			// 	}).then(() => {
			// 		document.id && this.props.sendEet(document.id)
			// 	})
			// 	break
			case 'accdoc_create_taxadvance':
				this.props.createTaxAdvanceAccountingDocument(document).then(this.gridRefresh)
				break
			// case 'accdoc_cancel_eet':
			// 	if (document && isEetSent(document.countryVariantSpecific)) {
			// 		ConfirmDialog(t('dialogs.cancelEet'), {
			// 			okLabel: t('dialogs.yesOption'),
			// 			cancelLabel: t('dialogs.noOption'),
			// 		}).then(() => {
			// 			document.id && this.props.cancelEet(document.id)
			// 		})
			// 	} else {
			// 		document.id && this.props.cancelEet(document.id)
			// 	}
			// 	break
			case 'accdoc_create_cr_accdoc':
				this.setState({ cashRegisterSelectionDialogOpened: true })
				break
			case 'accdoc_create_creditnote':
				this.props.createCreditNote(document)
				break
			case 'accdoc_movewf_processed':
				this.props.validateAccountingDocument(document.id, 'Processed').then((valid: boolean) => {
					if (true === valid) {
						if (this.props.preProcessingAccountingDocumentPreview) {
							this.openConfirmDialog()
						} else {
							this.onConfirmDocument(document)
						}
					} else {
						ConfirmDialog(t('dialogs.changeStateToProcessedWhenInvalid'), {
							okLabel: t('dialogs.changeStateToProcessedWhenInvalidYesOption'),
							cancelLabel: t('dialogs.closeOption'),
						}).then(() => {
							this.props.goOnEditAccountingDocumentRoute(document.id)
						})
					}
				})
				break
			default:
				this.props.defaultOnAction(action, document, this.gridRefresh)
		}
	}

	onActionMenuOpen = (document: AccountingDocument, needUpdate: () => void) => {
		this.setState({ availableActionsLoading: true })
		this.props
			.loadAvailableActions([this.props.accountingDocumentId])
			.then((availableActions: ?AccountingDocumentActions) => {
				availableActions &&
					this.setState({ availableActions, availableActionsLoading: false }, () => {
						needUpdate()
					})
			})
	}

	isVisibleAction = (type: string) => {
		return (row: AccountingDocument): boolean => {
			const {
				availableActions,
				internal,
				canEditIssuedAccountingDocuments,
				canEditReceivedAccountingDocuments,
				canEditUnknownAccountingDocuments,
				canProcessAccountingDocuments,
				canRemoveIssuedAccountingDocuments,
				canRemoveReceivedAccountingDocuments,
				canRemoveUnknownAccountingDocuments,
				canEditCashRegisters,
			} = this.props
			const permissions = {
				editIssuedAccountingDocuments: canEditIssuedAccountingDocuments,
				editReceivedAccountingDocuments: canEditReceivedAccountingDocuments,
				editUnknownAccountingDocuments: canEditUnknownAccountingDocuments,
				processAccountingDocuments: canProcessAccountingDocuments,
				removeIssuedAccountingDocuments: canRemoveIssuedAccountingDocuments,
				removeReceivedAccountingDocuments: canRemoveReceivedAccountingDocuments,
				removeUnknownAccountingDocuments: canRemoveUnknownAccountingDocuments,
				editCashRegisters: canEditCashRegisters,
			}

			if (!availableActions || !isGridActionAllowed(permissions, type, row)) {
				return false
			}

			// Pokladna - doklady, které nemají pripojeny scan, nemají mít možnost Znovu vytěžit.
			if (type === 'accdoc_reextract') {
				if (!row.scans || !row.scans.length) {
					return false
				}
			}

			// Vynutit zaúčtování a Změnit stav na Zaúčtováno nedavají u již zaúčtovaných dokladů smysl.
			// POZN: Vynutit zaúčtování - pro účetní nechat, je to znovuposlání již zaúčtovaného dokladu // do ESO (smaže doklad v ESO a potřebuje ho tam z nějakého důvodu poslat znovu,
			// např. změna měny atd)
			// viz TESTREP-305
			if (row && row.processingState === 1) {
				if (type === 'accdoc_force_processing' && !internal) return false
				if (type === 'accdoc_set_processing_state') return false
			}

			const availableActionsState: ?{ data?: ?AccountingDocumentActions } = availableActions[row.id || '']
			const _availableActions: ?AccountingDocumentActions = availableActionsState && availableActionsState.data

			if (!_availableActions) {
				return false
			}

			if (type === 'accdoc_copy') {
				const canCopy =
					(row.cashRegisterId ?? (this.props.internal || (!this.props.internal && row.direction === 1))) &&
					canBeAccDocCopied(row.type)
				if (!canCopy) return false
			}

			if ('accdoc_send_email' === type) {
				return row.direction === 1 && !this.isShowSendEmailButtonVisible(row)
			}

			if (type === 'accdoc_send_due_date') {
				return (
					_availableActions &&
					_availableActions.findIndex((availableAction: AccountingDocumentAction) => availableAction.type === type) !==
						-1 &&
					row.contact !== null
				)
			}

			return (
				_availableActions.findIndex((availabileAction: AccountingDocumentAction) => availabileAction.type === type) !==
				-1
			)
		}
	}

	isVisibleMoveAction = (state: string) => {
		const type = 'accdoc_movewf'
		return (): boolean => {
			const { availableActions } = this.state
			if (!availableActions) {
				return false
			}

			const action: ?AccountingDocumentAction = availableActions.find(
				(availabileAction: AccountingDocumentAction) => availabileAction.type === type,
			)

			if (!action || !action.params || !action.params.possibleStates) {
				return false
			}

			return action.params.possibleStates.findIndex((item: string) => item === state) !== -1
		}
	}

	renderMoreActions() {
		const { t } = this.props

		let hiddenActions = ['accdoc_show', 'accdoc_edit']
		if (!this.isReadOnly()) {
			hiddenActions = [...hiddenActions, 'accdoc_movewf_draft', 'accdoc_movewf_approval', 'accdoc_movewf_processed']
		}

		const actions = getAccountingDocumentActions(
			t,
			'unknown',
			this.props.availableActions,
			hiddenActions,
			this.isVisibleAction,
			this.isVisibleMoveAction,
		)

		const row: ?AccountingDocument = this.props.accountingDocument

		return row ? (
			<MoreActions
				loading={this.state.availableActionsLoading}
				actions={actions}
				row={row}
				onAction={this.onAction}
				onActionMenuOpen={this.onActionMenuOpen}
			/>
		) : null
	}

	handlePaymentClose = () => {
		this.setState(
			{
				accountingDocuments: null,
				paymentOpen: false,
			},
			() => {
				this.gridRefresh()
			},
		)
	}

	trackAccDocExtractingCanceled = () => {
		Tracking.trackAccDocExtractingCanceled(this.props.accountingDocument.id)
	}

	hasClass(element: Element, className: string) {
		if (element.classList) return element.classList.contains(className)
		else return !!element.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
	}

	addClass(element: Element, className: string) {
		if (element.classList) element.classList.add(className)
		else if (!this.hasClass(element, className)) element.className += ' ' + className
	}

	removeClass(element: Element, className: string) {
		if (element.classList) element.classList.remove(className)
		else if (this.hasClass(element, className)) {
			let reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
			element.className = element.className.replace(reg, ' ')
		}
	}

	updateLayout(wide: boolean) {
		const main = document.getElementById('main__wrapper')
		if (wide) {
			main && this.addClass(main, 'wide')
		} else {
			main && this.removeClass(main, 'wide')
		}
	}

	handleFileOpen(e: SyntheticMouseEvent<HTMLElement>, fileId?: string, fileName?: string) {
		fileId && fileName && openFile(fileId, fileName, 'popupPage')
	}

	getSideButtons = memoize((READONLY: boolean, scans: any) => {
		return (
			(READONLY &&
				scans &&
				scans.map((scan: AccountingDocumentScan) => {
					return {
						content: (
							<div style={{ margin: 5 }}>
								<TriviLink
									onClick={(e: SyntheticMouseEvent<HTMLElement>) => this.handleFileOpen(e, scan.fileId, scan.filename)}
									title={scan.filename}
								>
									<FileIcon fileName={scan.filename} large />
								</TriviLink>
							</div>
						),
					}
				})) ||
			[]
		)
	})

	getShowEditButton = () => {
		if (!this.props.accountingDocument) return false
		if (!this.isReadOnly()) return false

		const state = this.props.accountingDocument.state || ''
		const direction = Number_AccountingDocumentAssignedDirection(this.props.accountingDocument.direction)

		if (this.props.internal) {
			return (
				state !== 'Processed' &&
				((this.props.canEditIssuedAccountingDocuments && direction === 'issued') ||
					(this.props.canEditReceivedAccountingDocuments && direction === 'received'))
			)
		} else {
			return (
				direction === 'issued' &&
				this.props.canEditIssuedAccountingDocuments &&
				(state === 'Draft' || state === 'Fixing')
			)
		}
	}

	getContainer = () => {
		const READONLY = this.isReadOnly()
		const { t, accountingDocument, scans, internal, accountingDocumentId } = this.props
		const isExtractionQueue = getIsExtractionQueue(this.props)
		let Container
		let localProps = {}

		if (accountingDocument) {
			const direction = Number_AccountingDocumentAssignedDirection(accountingDocument.direction)
			const type = Number_AccountingDocumentAssignedType(accountingDocument.type)
			const MoreActions = this.renderMoreActions()
			const accountingDocumentState = (accountingDocument && accountingDocument.state) || ''
			const showEditButton = this.getShowEditButton()

			const sideButtons = this.getSideButtons(READONLY, accountingDocument.scans)

			if (!type || !direction) {
				Container = (
					<EditScannedInvoice
						accountingDocumentId={accountingDocumentId}
						internal={internal || false}
						onEditClick={this.onEditClick}
						scansCount={(scans && scans.length) || 0}
						view={this.state.view}
						readonly={READONLY}
						onViewChange={this.onViewChange}
						storing={this.props.storing || false}
						MoreActions={MoreActions}
						sideButtons={sideButtons}
						onCloseRedirectUrl={isExtractionQueue ? internalDashboardRoute() : undefined}
						isExtractionQueue={isExtractionQueue}
						onCloseCallback={isExtractionQueue ? this.trackAccDocExtractingCanceled : undefined}
					/>
				)
			} else {
				const showSendEmailButton = this.isShowSendEmailButtonVisible(accountingDocument)

				const commonProps: CommonProps = {
					MoreActions,
					accountingDocumentId,
					internal: internal || false,
					onEditClick: this.onEditClick,
					scansCount: (scans && scans.length) || 0,
					view: this.state.view,
					readonly: READONLY,
					onViewChange: this.onViewChange,
					storing: this.props.storing || false,
					accountingDocumentState,
					showSendEmailButton,
					showEditButton: showEditButton && this.props.readonly,
					showStateChanger: !showEditButton,
					showSaveAndStopExtraction: isExtractionQueue,
					showCashbotPanel: showCashbotPanelOnAccDoc(accountingDocument),
					isCashRegister: AccountingDocumentTypeNumber_isCashRegister(accountingDocument.type),
				}

				const additionalProps = {
					direction,
					type,
					sideButtons,
					title: t(ACCOUNTING_DOCUMENT_TITLES[direction][type]),
					contactSupplierTitle: direction === 'issued' ? t('invoice.contact.supplier') : t('invoice.contact.custommer'),
					contactBuyerTitle: direction === 'issued' ? t('invoice.contact.custommer') : t('invoice.contact.supplier'),
					onClose: this.onClose,
				}

				switch (accountingDocument.type) {
					case AccountingDocumentType_Number('invoice'):
					case AccountingDocumentType_Number('advance'):
					case AccountingDocumentType_Number('tax_advance'):
					case AccountingDocumentType_Number('credit_note'):
						if (isExtractionQueue) {
							localProps.onCloseRedirectUrl = internalDashboardRoute()
						}
						Container = (
							<EditInvoiceTypeDocument
								key={accountingDocumentId}
								{...commonProps}
								{...additionalProps}
								{...localProps}
							/>
						)
						break
					case AccountingDocumentType_Number('simplified_invoice'):
						Container = <EditSimplifiedInvoice {...commonProps} {...additionalProps} />
						break
					case AccountingDocumentType_Number('cash_receipt'):
					case AccountingDocumentType_Number('cash_transfer'):
					case AccountingDocumentType_Number('cash_uncategorized'):
					case AccountingDocumentType_Number('employee_advance'):
						if (type !== 'cash_receipt') {
							localProps.showAccountingDocumentConnection = false
						}
						Container = <EditSimpleDocument {...commonProps} {...additionalProps} {...localProps} />
						break
				}
			}
		}

		return Container
	}

	getFilter = memoize(
		(
			direction: ?AccountingDocumentDirection,
			accountingDocument: ?AccountingDocument,
			defaultFilter: ?Filter,
			view: ?AccountingDocumentView,
		) => {
			const cashRegisterId: ?string = accountingDocument && accountingDocument.cashRegisterId
			const isAccounting: ?boolean = cashRegisterId == null
			let filter = [...(defaultFilter || [])]

			if (isAccounting) {
				switch (direction) {
					case 'received':
					case 'issued':
						filter.push({
							field: 'direction',
							value: direction === 'received' ? 0 : 1,
						})
						filter.push({
							field: 'excludeAccDocSearchThresholdId',
							value: true,
						})
						switch (view) {
							case 'all':
								filter.push({
									field: 'type',
									valueContains: [0, 1, 2, 3],
								})
								break
							case 'isProcessedWithoutOK':
								filter.push(
									{
										field: 'type',
										valueContains: [0, 1, 2, 3],
									},
									{
										field: 'isProcessedWithOK',
										value: false,
									},
								)
								break
							case 'errors':
								filter.push(
									{
										field: 'type',
										valueContains: [0, 1, 2, 3],
									},
									{
										field: 'processingState',
										value: 3,
									},
								)
								break
							case 'drafts':
								filter.push(
									{
										field: 'type',
										valueContains: [0, 1, 2, 3],
									},
									{
										field: 'state',
										value: 'Draft',
									},
								)
								break
						}
						break
					case 'unknown':
						filter.push({
							field: 'type',
							value: 9,
						})
						break
				}
			} else if (cashRegisterId) {
				filter = [
					...filter,
					{
						field: 'type',
						valueContains: [
							AccountingDocumentType_Number('cash_receipt'),
							AccountingDocumentType_Number('simplified_invoice'),
							AccountingDocumentType_Number('employee_advance'),
							AccountingDocumentType_Number('cash_transfer'),
							AccountingDocumentType_Number('cash_uncategorized'),
						],
					},
					{
						field: 'cashRegisterId',
						value: cashRegisterId,
					},
					{
						field: 'excludeAccDocSearchThresholdId',
						value: true,
					},
				]
			}

			return filter
		},
	)

	getRowClassName = (item: AccountingDocument) => {
		if (this.props.accountingDocument && item.id === this.props.accountingDocument.id) {
			return 'selected'
		}
	}

	// closeNewTaskDialog = () => {
	// 	this.setState({ newTaskDialogOpened: false })
	// }

	closeCashRegisterDialog = () => {
		this.setState({ cashRegisterSelectionDialogOpened: false })
	}

	handleCashRegisterSelectionSubmit = (cashRegister: ?CashRegister): Promise<*> => {
		const { accDocCreateAccDoc, accountingDocument } = this.props
		return accDocCreateAccDoc(cashRegister, accountingDocument)
	}

	openConfirmDialog = () => {
		this.setState({ isConfirmDialogOpen: true })
	}

	closeConfirmDialog = () => {
		this.setState({ isConfirmDialogOpen: false })
	}

	onConfirmDocument = (accountingDocument?: AccountingDocument) => {
		this.closeConfirmDialog()
		if (accountingDocument) {
			this.props.defaultOnAction('accdoc_movewf_processed', accountingDocument, this.gridRefresh)
		} else {
			this.props.defaultOnAction('accdoc_movewf_processed', this.props.accountingDocument, this.gridRefresh)
		}
	}

	getDirection = (isAccounting: ?boolean) => {
		const accountingDocumentDirection =
			this.props.accountingDocument && Number_AccountingDocumentDirection(this.props.accountingDocument.direction)

		if (isAccounting && this.props.direction && 'unknown' !== this.props.direction) {
			return this.props.direction
		} else if (!isAccounting && this.props.direction) {
			return this.props.direction
		} else {
			return accountingDocumentDirection
		}
	}

	render() {
		if (!this.props.accountingDocument) return null

		const { t, accountingDocument, canSeeBankAccounts, history } = this.props
		const READONLY = this.isReadOnly()
		let Container = this.getContainer()

		const cashRegisterId: ?string = accountingDocument && accountingDocument.cashRegisterId
		const isAccounting: ?boolean = cashRegisterId == null

		const isNonCashRegister = (accountingDocument && AccountingDocument_isNonCashRegister(accountingDocument)) || false
		const direction = this.getDirection(isAccounting)

		const filter = this.getFilter(direction, accountingDocument, this.props.defaultFilter, this.props.view)
		const gridStateKey = getDataGridId(this.props)

		const { sendEmailDialogDocument, sendReminderEmailDialogDocument } = this.state
		const isUsedOptimizeFilter =
			filter.findIndex((f: any) => f.field === 'excludeAccDocSearchThresholdId' && !f.value) > -1

		return (
			<div className={READONLY ? styles.readonly : this.state.view === 'scans' ? styles.scans : styles.normal}>
				{READONLY && (
					<div className={styles.files}>
						<div className={styles.headline} onClick={this.onBackButtonClick}>
							<ArrowLeft style={style.arrow} hoverColor={colors.black} color={colors.black} />
							{history.location.state && history.location.state.link
								? history.location.state.link
								: isAccounting
								? t('navigation.accountingDocument.label')
								: t('navigation.cashRegister')}
						</div>
						<div className={styles.viewSwitcher} style={style.viewSwitcher}>
							{isAccounting ? (
								<DetailDirectionViewSwitcher direction={direction} />
							) : (
								<CashRegisterDirectionViewSwitcher direction={direction} />
							)}
						</div>
						{isAccounting ? (
							<AccountingDocumentAdvancedFilter
								isUsedOptimizeFilter={isUsedOptimizeFilter}
								compact
								name={getAccDocFilterName(direction)}
							/>
						) : (
							<CashRegisterPaymentsAdvancedFilter
								name={getCashRegisterFilterName(direction)}
								compact
								cashRegisterId={(accountingDocument && accountingDocument.cashRegisterId) || ''}
								direction={(accountingDocument && accountingDocument.direction) || undefined}
								type={(accountingDocument && accountingDocument.type) || undefined}
								gridId={'cashRegisterPayments' + accountingDocument.cashRegisterId}
								defaultFilter={filter}
								internal={this.props.internal}
							/>
						)}

						{gridStateKey && (
							<DataGrid
								id={gridStateKey}
								filterId={
									isAccounting
										? getAccDocFilterName(direction || 'issued')
										: getCashRegisterFilterName(direction || 'issued')
								}
								dataType="accountingDocuments"
								defaultFilter={filter}
								currentRowId={(this.props.accountingDocument && this.props.accountingDocument.id) || undefined}
								showPageSize={false}
								showItemsOfTotal={false}
								showPrevButton={false}
								showNextButton={false}
							>
								{getCompactInfoColumn({
									t,
									onClick: this.changeDocument,
									forceGoOnEditRoute: this.props.forceGoOnEditRoute,
									isAccounting,
								})}
							</DataGrid>
						)}
					</div>
				)}

				<div className={READONLY ? styles.readOnlyContainer : styles.container}>{Container}</div>

				<CreditNoteDialog
					open={this.state.creditNoteOpen}
					hideCreditNote={this.hideCreditNote}
					saveCreditNote={this.saveCreditNote}
				/>

				{isNonCashRegister && canSeeBankAccounts && (
					<PaymentDialog
						onRequestClose={this.handlePaymentClose}
						accountingDocuments={this.state.accountingDocuments || EMPTY_ARRAY}
						open={this.state.paymentOpen}
					/>
				)}

				<SendEmailDialog
					open={!!sendEmailDialogDocument}
					onClose={this.closeEmailSendDialog}
					onSend={this.onSendDocument}
					accountingDocumentId={sendEmailDialogDocument && sendEmailDialogDocument.id}
				/>

				<SendReminderEmailDialog
					onClose={this.closeReminderEmailSendDialog}
					onSend={this.onSendReminder}
					accountingDocumentId={sendReminderEmailDialogDocument && sendReminderEmailDialogDocument.id}
					open={!!sendReminderEmailDialogDocument}
				/>

				{/*{this.state.newTaskDialogOpened && (*/}
				{/*	<TaskEdit redirectBack onClose={this.closeNewTaskDialog} defaultData={this.generateNewTask()} />*/}
				{/*)}*/}
				<CashRegisterDialog
					open={this.state.cashRegisterSelectionDialogOpened}
					onRequestClose={this.closeCashRegisterDialog}
					onSubmit={this.handleCashRegisterSelectionSubmit}
					currency={accountingDocument ? accountingDocument.currency : ''}
				/>
				<DocumentPreviewDialog
					open={this.state.isConfirmDialogOpen}
					onClose={this.closeConfirmDialog}
					onConfirm={this.onConfirmDocument}
					accountingDocumentId={accountingDocument ? accountingDocument.id : null}
				/>
			</div>
		)
	}
}

const style = {
	arrow: {
		verticalAlign: 'middle',
		marginRight: 5,
		position: 'relative',
		top: -1,
	},
	viewSwitcher: {
		marginRight: '20px',
	},
}

export default withTranslate(
	withNotify(withRouter(withAccountingDocumentActions(withOrganizationSettings(EditAccountingDocument)))),
)
